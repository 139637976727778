import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import HeroSlider from '../../../components/HeroSlider'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Portfolio from '../../../components/Portfolio'
import Testmonial from "../../../components/Testmonial";
import ContactArea from '../../../components/ContactArea'
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import BlogArea from '../../../components/BlogArea'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/about/2.jpg'
import signature from '../../../images/about/1.png'

// images
import portfolio1 from '../../../images/studies/1.jpg'
import portfolio2 from '../../../images/studies/2.jpg'
import portfolio3 from '../../../images/studies/3.jpg'
import portfolio4 from '../../../images/studies/4.jpg'
import portfolio5 from '../../../images/studies/5.jpg'

const aboutText = [
    { text: 'TDMとは「低頭身デフォルメマスコット」の略です。頭身が低くデフォルメが効いたデザインかつマスコットであるキャラクターのことを指します。日本では、「ゆるキャラ」を中心に愛されている表現手法でもあります。' },
]

const heroSliders = [
    {
        images: 'slideWrapperOne',
        title: 'T.D.M.5 in Kemoket 9.5',
        subTitle: '2020.11.21 (Sat.) 11:00 -',
        text: 'TDMオンリーアンソロジー',
        button: 'Details'
    },
    {
        images: 'slideWrapperThree',
        title: 'T.D.M.5 Designed Card 1.',
        subTitle: '¥2,000 -',
        text: 'Author: タオル',
        button: 'Details'
    },
    {
        images: 'slideWrapperTwo',
        title: 'Other T.D.M. books in Kemoket 9.5',
        subTitle: '2020.11.21 (Sat.) 11:00 -',
        text: '過去のTDMオンリーアンソロジー',
        button: 'Details'
    },
]

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'It is a long established fact that a reader will be distracted by the readable content of '
    },
    {
        icon: 'flaticon-wounded',
        title: 'Personal Injury',
        content: 'It is a long established fact that a reader will be distracted by the readable content of '
    },
    {
        icon: 'flaticon-employee',
        title: 'Business Law',
        content: 'It is a long established fact that a reader will be distracted by the readable content of '
    },
    {
        icon: 'flaticon-thief',
        title: 'Criminal Law',
        content: 'It is a long established fact that a reader will be distracted by the readable content of '
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Education Law',
        content: 'It is a long established fact that a reader will be distracted by the readable content of '
    },
    {
        icon: 'flaticon-house',
        title: 'Real Estate Law',
        content: 'It is a long established fact that a reader will be distracted by the readable content of '
    },
]

const portfolioItem = [
    { images: portfolio1, title: 'General Service', subtitle: 'Corporate' },
    { images: portfolio2, title: 'Personal Issue', subtitle: 'General' },
    { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
    { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
    { images: portfolio5, title: 'Business Accounting', subtitle: 'Family Issue' }
]

const HomePageOne = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea" />
            </header>
            <HeroSlider 
                sliders={heroSliders}
                className="heroSliderArea" />
            <About
                className="aboutArea"
                title="About T.D.M."
                images={about}
                signature={signature}
                pragraphs={aboutText}
            />
            <TeamMember
                title="T.D.M.5 Creators"
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <CounterArea
                className="counterArea"
            />
            <BlogArea
                className="blogArea"
                title="Company"
                subTitle="Our Company"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default HomePageOne
