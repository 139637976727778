import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import TeamMember from '../../../components/TeamMember'
import CounterArea from '../../../components/CounterArea'
import BlogArea from '../../../components/BlogArea'
import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/about/2.jpg'
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'

const aboutText = [
    { text: '2020年11月21日(土)に開催される「けもケット9.5」にて、TDMシリーズ第五弾となる「TDM5」を頒布します！　今回は架空の企業を題材とし、それらの企業の顔である「企業マスコット」中心のお話をオムニバス形式でまとめた本となっております。' },
    { text: '10人の豪華なメンバーと15を超えるTDMが登場します。けもケット9.5では、過去のTDMシリーズをすべて頒布しますのでこれまでに買い逃してしまったシリーズがある場合は、ぜひお手にとってみてください。（TDM1〜3は、1冊にまとまった「TDM 1.2.3 Pack」として頒布します。単体の頒布はございませんのでご了承ください。)' },
    { text: 'TDMとは「低頭身デフォルメマスコット」の略です。頭身が低くデフォルメが効いたデザインかつマスコットであるキャラクターのことを指します。日本では、「ゆるキャラ」を中心に愛されている表現手法でもあります。' },
]

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-wounded',
        title: 'Personal Injury',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-employee',
        title: 'Business Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-thief',
        title: 'Criminal Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Education Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-house',
        title: 'Real Estate Law',
        content: 'There are many variations of passages of Lorem '
    },
]

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'About'}
]

const AboutPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="About"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="T.D.M.5 in Kemoket 9.5"
                subTitle="We Are TDMer"
                images={about}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />
            <TeamMember
                title="T.D.M.5 Creators"
                subTitle="Meet Our TDMers"
                className="teamArea"
                slider={true}
            />
            <BlogArea
                className="blogArea"
                title="Company"
                subTitle="Our Company"
            />
            <FooterArea/>
        </Fragment>
    )
}
export default AboutPage
