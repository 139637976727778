import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

import blog1 from '../../images/blog-page/taoru.jpg'
import blog2 from '../../images/blog-page/nettu.jpg'
import blog3 from '../../images/blog-page/rao.jpg'
import blog4 from '../../images/blog-page/nansiro.jpg'
import blog5 from '../../images/blog-page/buro.jpg'
import blog6 from '../../images/blog-page/kajiura.jpg'
import blog7 from '../../images/blog-page/buke.jpg'
import blog8 from '../../images/blog-page/yoyo.jpg'
import blog9 from '../../images/blog-page/norio.jpg'
import blog10 from '../../images/blog-page/udon.jpg'
import avatar from '../../images/blog-page/6.jpg'
const blogPost = [
    {
        image: blog1,
        title: 'TDMホールディングス',
        text: '様々なTDM事業を行う企業を子会社化し、経営戦略の円滑化を図るために設立された会社です。代表はお金が大好きなうさぎTDMの「マネうさ」。お金のためならなんでもします。',
    },
    {
        image: blog2,
        title: 'TDMモーター',
        text: '1929年に株式会社小人製作所を設立し、1965年に省電力小型モーターブランド「TDM」を商標登録。その後に急成長を遂げ、2005年にTDMモーター株式会社に改称しました。2016年からはSDエレクトロニクス株式会社と合併してAIを用いた最新の高精度センサ開発事業にも取り組んでいます。カワセミTDMの「モッティ」が当社のマスコットキャラクターを務めています。',
    },
    {
        image: blog3,
        title: 'Mascorganism',
        text: '2005年にペット型ロボット事業を展開するTD Robot株式会社を設立。2007年から2008年にかけてマスコット型ロボットを開発する複数の会社の株式を取得し、子会社化させました。2010年からはロボットの内側を作ることに事業転換して低頭身系ロボット開発用OS「T」を提供しました。2020年は人工生命体に注力、TDM系人工生命体開発用OS「M」を提供しました。OS「M」が搭載された人工生命体TDM「SK-M」が当社の製品兼マスコットキャラクターです。',
    },
    {
        image: blog4,
        title: '帝東製菓',
        text: '1965年に帝東薬品工業を設立。分社化、法人化を経て1990年に株式会社帝東薬品工業を設立。順調に成長を遂げ、2000年には帝東ホールディングス株式会社を設立。同年に分社化していた複数の会社の株式を取得して帝東HDの子会社となりました。2014年は2020年夏季スポーツイベントのオフィシャルスポンサーとして当社が就任し、2019年にTDMの「フリウ」がマスコットキャラクター兼TDM関連事業の主任研究者として入社しました。経口保水飲料「OSH-One」は様々な場面で飲まれている当社主力製品です。',
    },
    {
        image: blog5,
        title: '枡肉食品',
        text: 'やわらかくて上質な味を持つ極秘の「枡肉」を製造、販売している食品会社。当社のイメージキャラクター「ビフタくん」はおいしさの秘訣を「特別な食材・徹底した安全管理・洗練された精肉作業であり、特にカッティングが命」と語っています。他の食品会社と違い、当社は「深夜営業」を通して製品の質の向上に努めています。',
    },
    {
        image: blog6,
        title: 'TDM.com',
        text: 'アダルト向けグッズメーカーの老舗。アダルトグッズに対する偏見や障壁を無くすために特徴的な広報をしています。現代の広報に合わせた「SNS広報」などを主軸として、広報を担当するキャストにもこだわりを持っています。今年からうさぎTDMの「うさぎちゃん」がグッズの魅力を伝えるべく、本人が実際に使用する動画などを通して紹介します。',
    },
    {
        image: blog7,
        title: 'ピュアクララ',
        text: '1994年に近辺の川から汲み上げてきた淡水を販売する事業を設立。2006年頃、誰も使っていない空き地に本社を設立、資本金は0円。2012年にＴＤＭ証券取引所市場第二部に上場。2014年にＴＤＭ証券取引所市場第一部に昇格。2016年から2017年にかけて複数の食品会社を子会社化、社内の働き方を大幅に改善。2018年に地球温暖化への取り組みとして、かき氷を大量に作ることで平均気温を下げることに成功。翌年はその取り組みが海面上昇に影響した。2020年に株式会社ピュアクララ公式ＴＤＭ「ピュア＆クララ」が誕生。新たな事業を作るための先駆けとして活躍しています。',
    },
    {
        image: blog8,
        title: 'TTSアクアリウム',
        text: '1996年にTTS海洋生物飼育展示場として運営開始。一年後、来場者の増加に伴い1999年にTTS水族館として開館。2000年に当水族館のマスコットとして「ドロップ」が就任。2002年に主力のショーとなるイルカショーの開催開始。2006年に運営開始10周年を記念して当館の名称を「TTSアクアリウム」へ改名しました。2015年には水族館だけではなく、遊園地事業にも取り組んでいます。2020年に当館のマスコットキャラクターである「ドロップ」が館内スタッフとして就任しました。',
    },
    {
        image: blog9,
        title: 'MMNホールディングス',
        text: '????年にMNNホールディングス魔界本社を設立。CEOのアヴァリディアは、自身をマスコットとした会社を人間社会に紛れ込ませて、アミューズメントをはじめとした様々な業界で大成功を収めました。大成功の秘密は、商品にかけた「とある呪い」が大きく影響しているようです。',
    },
    {
        image: blog10,
        title: 'ドラまくら',
        text: 'やわらかさを追求する寝具会社。当社のノウハウをすべて注ぎ込んだ「ドラまくら」の販売を促進するために、ドラゴンTDMの「ドラまくらくん」を手配。社長は、ドラまくらくんの可愛さを活かそうと極秘で製作をしていた最強のまくらを発表するらしいです。そのまくらは「全身で柔らかいまくらを体験」できるそうです。',
    },
]

const BlogPost = () => {
    return (
        <Fragment>
            {blogPost.map((blog, i) => (
                <div key={i} className="blogPostWrapper">
                    <div className="blogPostImg">
                        <img src={blog.image} alt="" />
                    </div>
                    <div className="blogPostContent">
                        <ul className="blogPostMeta">
                        </ul>
                        <h3><Link to='blog-details'>{blog.title}</Link></h3>
                        <p>{blog.text}</p>
                    </div>
                </div>
            ))}
        </Fragment>
    )
}
export default BlogPost
