import React from 'react'
import { Link } from 'react-router-dom'
import 'react-modal-video/scss/modal-video.scss'
import './style.scss'

const About = ({ subTitle, title, videoId, className, signature, pragraphs,images,orderLast }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="aboutContent">
                            {subTitle && <span>{subTitle}</span>}
                            <h2>{title}</h2>
                            {pragraphs.map((pragraph, i) => (
                                <p key={i}>{pragraph.text}</p>
                            ))}
                            {signature && <div className="signature">
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default About
