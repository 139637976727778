import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from '../Title'
import './style.scss'

// images
import teamMember1 from '../../images/expert/1.jpg'
import teamMember2 from '../../images/expert/2.jpg'
import teamMember3 from '../../images/expert/3.jpg'
import teamMember4 from '../../images/expert/4.jpg'
import teamMember5 from '../../images/expert/5.jpg'
import teamMember6 from '../../images/expert/6.jpg'
import teamMember7 from '../../images/expert/7.jpg'
import teamMember8 from '../../images/expert/8.jpg'
import teamMember9 from '../../images/expert/9.jpg'
import teamMember10 from '../../images/expert/10.jpg'

const teams = [
    {
        name: 'Taoru', level: 'TDMホールディングス', image: teamMember1, socialMedia: [
            {
                name: 'twitter',
                link: 'https://twitter.com/taollli'
            }
        ]
    },
    {
        name: 'Nettu', level: 'TDMモーター', image: teamMember2, socialMedia: [
            {
                name: 'twitter',
                link: 'https://twitter.com/nyankamedon'
            }
        ]
    },
    {
        name: 'RAO', level: 'Mascorganism', image: teamMember3, socialMedia: [
            {
                name: 'twitter',
                link: 'https://twitter.com/RIORAO'
            }
        ]
    },
    {
        name: 'Nansiro', level: '帝東製菓', image: teamMember4, socialMedia: [
            {
                name: 'twitter',
                link: 'https://twitter.com/LuxAlice_'
            }
        ]
    },
    {
        name: 'Buro', level: '枡肉食品', image: teamMember5, socialMedia: [
            {
                name: 'twitter',
                link: 'https://twitter.com/blocktw'
            }
        ]
    },
    {
        name: 'Kajiura', level: 'TDM.com', image: teamMember6, socialMedia: [
            {
                name: 'twitter',
                link: 'https://twitter.com/k_kajiura'
            }
        ]
    },
    {
        name: 'Buke', level: 'ピュアクララ', image: teamMember7, socialMedia: [
            {
                name: 'twitter',
                link: 'https://twitter.com/Buke1128'
            }
        ]
    },
    {
        name: 'Yoyo', level: 'TTSアクアリウム', image: teamMember8, socialMedia: [
            {
                name: 'twitter',
                link: 'https://twitter.com/yoyodol09'
            }
        ]
    },
    {
        name: 'Norio', level: 'MMNホールディングス', image: teamMember9, socialMedia: [
            {
                name: 'twitter',
                link: 'https://twitter.com/noriburu'
            }
        ]
    },
    {
        name: 'Udon', level: 'ドラまくら', image: teamMember10, socialMedia: [
            {
                name: 'twitter',
                link: 'https://twitter.com/udon_3'
            }
        ]
    },
]

const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const TeamMember = ({ className, title, subTitle, slider, noGutters }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className={!noGutters ? 'row' : 'row no-gutters'}>
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    {slider ? (
                        <div className="col-12">
                            <Slider className="teamSlideArea" {...settings}>
                                {teams.map((team, i) => (
                                    <div key={i} className="teamWrapper">
                                        <div className="teamImage">
                                            <img src={team.image} alt="" />
                                        </div>
                                        <div className="teamContent">
                                            <h3>{team.name}</h3>
                                            <span>{team.level}</span>
                                            <ul>
                                                {team.socialMedia.map(social => (
                                                    <li key={social.name}><a href={social.link}><i className={`fa fa-${social.name}`} aria-hidden="true"></i></a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    ) : (
                            <Fragment>
                                {teams.map((team, i) => (
                                    <div key={i} className="col-lg-4 col-md-6 col-12">
                                        <div className="teamWrapper">
                                            <div className="teamImage">
                                                <img src={team.image} alt="" />
                                            </div>
                                            <div className="teamContent">
                                                <h3>{team.name}</h3>
                                                <span>{team.level}</span>
                                                <ul>
                                                    {team.socialMedia.map(social => (
                                                        <li key={social.name}><a href={social.link} target="_blank"><i className={`fa fa-${social.name}`} aria-hidden="true"></i></a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Fragment>
                        )}
                </div>
            </div>
        </div>
    )
}
export default TeamMember
